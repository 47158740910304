import React from 'react';
import s from './SecureCheckout.scss';
import {classes} from './SecureCheckout.st.css';
import Lock from '../../../assets/images/Lock.svg';
import classNames from 'classnames';

export enum SecureCheckoutDataHook {
  lock = 'SecureCheckoutDataHook.lock',
  text = 'SecureCheckoutDataHook.text',
  root = 'SecureCheckoutDataHook.root',
}

export const SecureCheckout = ({
  text,
  shouldUseNewTagsForCart,
  useCustomizableCounterInCart,
}: {
  text: string;
  shouldUseNewTagsForCart: boolean;
  useCustomizableCounterInCart?: boolean;
}) => {
  /* istanbul ignore else */
  if (shouldUseNewTagsForCart) {
    return (
      <p
        className={
          useCustomizableCounterInCart ? classNames(s.secureCheckout, classes.secureCheckout) : s.secureCheckout
        }
        data-hook={SecureCheckoutDataHook.root}>
        <Lock className={s.secureCheckoutLock} data-hook={SecureCheckoutDataHook.lock} />
        <span className={s.secureCheckoutText} data-hook={SecureCheckoutDataHook.text}>
          {text}
        </span>
      </p>
    );
  } else {
    return (
      <div className={s.secureCheckout} data-hook={SecureCheckoutDataHook.root}>
        <Lock className={s.secureCheckoutLock} data-hook={SecureCheckoutDataHook.lock} />
        <span className={s.secureCheckoutText} data-hook={SecureCheckoutDataHook.text}>
          {text}
        </span>
      </div>
    );
  }
};
