import React from 'react';
import {RemoveIcon} from './RemoveIcon';
import {CartItemDataHook} from '../../CartItem';
import {useControllerProps} from '../../../../../../domain/controllers/ControllerContext';
import s from '../../CartItem.scss';
import {classes} from '../../CartItem.st.css';
import {ICartItem} from '../../../../../../types/app.types';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {DeleteSmall} from '@wix/wix-ui-icons-common/on-stage';

export const Remove = ({item}: {item: ICartItem}) => {
  const {t} = useTranslation();
  const {removeItemFromCart, shouldUseDeleteIconOnLineItems, useCustomizableCounterInCart} =
    useControllerProps().cartStore;

  return shouldUseDeleteIconOnLineItems ? (
    <button
      className={useCustomizableCounterInCart ? classes.remove : s.removeOld}
      type="button"
      aria-label={t('cart.sr_remove_item_from_cart', {item_name: item.product.name})}
      onClick={() => removeItemFromCart(item)}
      data-hook={CartItemDataHook.Remove}>
      <DeleteSmall />
    </button>
  ) : (
    <button
      className={s.remove}
      type="button"
      aria-label={t('cart.sr_remove_item_from_cart', {item_name: item.product.name})}
      onClick={() => removeItemFromCart(item)}
      data-hook={CartItemDataHook.Remove}>
      <RemoveIcon />
    </button>
  );
};
