import React from 'react';
import {CartItemDataHook} from '../../CartItem';
import {ICartItem} from '../../../../../../types/app.types';
import s from '../../CartItem.scss';

export const Name = ({item, shouldUseNewTagsForCart}: {item: ICartItem; shouldUseNewTagsForCart: boolean}) => {
  const {name} = item.product;

  /* istanbul ignore else */
  if (shouldUseNewTagsForCart) {
    return (
      <h3 data-hook={CartItemDataHook.Name} className={s.productName}>
        {name}
      </h3>
    );
  } else {
    return (
      <div data-hook={CartItemDataHook.Name} className={s.productNameOld}>
        {name}
      </div>
    );
  }
};
